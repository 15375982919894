// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HZ3FmzBiQ"];

const serializationHash = "framer-h8Z56"

const variantClassNames = {HZ3FmzBiQ: "framer-v-h757n3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({fontSize, height, id, title, width, ...props}) => { return {...props, GL5FSV6cA: fontSize ?? props.GL5FSV6cA ?? 80, HG0I0fxHa: title ?? props.HG0I0fxHa ?? "Creative Vision"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;fontSize?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HG0I0fxHa, GL5FSV6cA, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HZ3FmzBiQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-h757n3", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HZ3FmzBiQ"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-6b19d04a-c8c7-46ac-8bc0-4380931dccd5, rgba(255, 255, 255, 0.5))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-6486a9ac-e9e8-4ced-becb-afb4c654ba35, rgb(0, 0, 0))", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7Q2xhc2ggRGlzcGxheS1tZWRpdW0=", "--framer-font-family": "\"Clash Display\", \"Clash Display Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-GL5FSV6cA-GIQFk1fRk) * 1px)", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "1em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234)))"}}>Creative Vision</motion.p></React.Fragment>} className={"framer-19ckxiu"} fonts={["FS;Clash Display-medium"]} layoutDependency={layoutDependency} layoutId={"YzxpF_wKe"} style={{"--extracted-r6o4lv": "var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-GL5FSV6cA-GIQFk1fRk": GL5FSV6cA}} text={HG0I0fxHa} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h8Z56.framer-5u9ikq, .framer-h8Z56 .framer-5u9ikq { display: block; }", ".framer-h8Z56.framer-h757n3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 28px; position: relative; width: 630px; }", ".framer-h8Z56 .framer-19ckxiu { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-h8Z56.framer-h757n3 { gap: 0px; } .framer-h8Z56.framer-h757n3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-h8Z56.framer-h757n3 > :first-child { margin-left: 0px; } .framer-h8Z56.framer-h757n3 > :last-child { margin-right: 0px; } }", ".framer-h8Z56[data-border=\"true\"]::after, .framer-h8Z56 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 136
 * @framerIntrinsicWidth 630
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"HG0I0fxHa":"title","GL5FSV6cA":"fontSize"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGIQFk1fRk: React.ComponentType<Props> = withCSS(Component, css, "framer-h8Z56") as typeof Component;
export default FramerGIQFk1fRk;

FramerGIQFk1fRk.displayName = "Values";

FramerGIQFk1fRk.defaultProps = {height: 136, width: 630};

addPropertyControls(FramerGIQFk1fRk, {HG0I0fxHa: {defaultValue: "Creative Vision", displayTextArea: false, title: "Title", type: ControlType.String}, GL5FSV6cA: {defaultValue: 80, title: "Font Size", type: ControlType.Number}} as any)

addFonts(FramerGIQFk1fRk, [{explicitInter: true, fonts: [{family: "Clash Display", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/2GQIT54GKQY3JRFTSHS4ARTRNRQISSAA/3CIP5EBHRRHE5FVQU3VFROPUERNDSTDF/JTSL5QESUXATU47LCPUNHZQBDDIWDOSW.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})